import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Projects = () => (
  <Layout linkColor="#5388ff">
    <SEO title="Projects" />
    <div className="wavy">
    <div className="paddedBody grayBody">
    <h1>Projects</h1>
    <h2>Universal Medical Data Project</h2>
    <p>Healthcare data is the rope in a tug-of-war. Its utility for helping patients is only the beginning. Putting it together with the data from others and tagging it with their demographic information can lead to massive leaps in insight into the cause and potential treatment of disease. If genetic data is added in, then the potential of both individual and societal advancements increase by orders of magnitude.</p>
    <p>The other side of the tug-of-war is the issue of privacy and the danger of what could happen if bad actors had unfettered access to the information. The horror of what could go wrong has been touched upon by apocalyptic science fiction, but the danger is very real.</p>
    <p>What we as a society have been left with is a series of complex compromises that swing pendulum-like between utility and security, using innovation shackled by legislation and regulatory processes. As a result, we gain neither the benefits nor achieve the desired safety.</p>
    <p>Using blockchain ledgers, Jonar Labs has built a prototype of a mechanism to make data highly available but also highly protected where the individual is in charge of unlocking their privacy. We conceived of it as a powerful repository so that any EMR, Digital Health or other systems could access it so long as it adhered to the security rules.</p>
    <p>From there, we sought to add in people’s genomes as part of the data set. We were able to add this massive data set in a way that kept the file size small enough that it could be efficiently stored and aggregated to be used in algorithmic processing.</p>
    <h2>Guichet Bitcoin</h2>
    <p>At Jonar Labs, we believe that the decentralized technologies can help our society become more democratic and equal. We want to put products out into the world that create confidence around decentralized technologies. We started with a Bitcoin and Ethereum crypto exchange.</p>
    <p>We understand that there’s been a lot of misinformation and misunderstanding around blockchain-based currencies. For one, the bitcoin blockchain is incredibly safe, much safer than any currency exchange mechanism that any bank would have.. The problem is that many bitcoin exchanges store private key information in centralized databases that get hacked. We created a service that stores none of that information and delivers cash for crypto and vice-versa, in a timely and effective manner. We wanted to create a product that leverages the entire power of the bitcoin blockchain, which we did.</p>
    <p>More information: <a href="https://www.guichetbitcoin.com">https://www.guichetbitcoin.com</a></p>
    <h2>One-click Blockchain</h2>
    <p>One great thing about blockchains is their capacity to allow thought experiments to become reality. Economists from Smith, to Cournot, to Nash could only have dreamed of a tool powerful enough to bring actors together where game theory could be tested in a real way. Consensus mechanisms in the blockchain have the potential to be some of the greatest collaborative tools of our society in the 21st century. The problem is that it’s still incredibly difficult to launch blockchains easily. With the one-click Blockchain project, Jonar Labs is working on a system to deploy Hyperledger nodes in one click. As a step further in this project we’re also proposing customizable blockchains to easily code and test consensus algorithms.</p>
    <p>Learn more at: <a href="https://www.youtube.com/watch?v=emK0MwriRhw&t=1s">https://www.youtube.com/watch?v=emK0MwriRhw&t=1s</a></p>
  </div></div>
  </Layout>
)

export default Projects
